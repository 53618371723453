import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import store from "./store";
import LangEn from './store/lang/en'
import LangZh from './store/lang/zh'
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

function lang() {
  let localLanguage = localStorage.getItem('language')
  if (localLanguage) return localLanguage
  // 默认中文
  else return 'zh'
}

let language = lang()

const i18n = createI18n({
  locale: language,
  fallbackLocale: 'zh',
  messages: {
    'en': LangEn,
    'zh': LangZh
  },
  silentTranslationWarn: process.env.NODE_ENV === 'development'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // console.log(to);
    if (!store.state.token) {
      if (to.fullPath == "/") {
        next({ name: 'Login' })
      } else {
        next({ name: 'Login', query: { redirect: to.fullPath } })
      }
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, axios)
  .mount('#app')
