import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    name: 'Index',
    path: '/',
    component: () => import('@/pages/FrontPage'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/pages/LogIn'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'AddProfile',
    path: '/add_profile',
    component: () => import('@/pages/AddProfile'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'ForgetPwd',
    path: '/forget',
    component: () => import('@/pages/ForgetPwd'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'SignUp',
    path: '/signup',
    component: () => import('@/pages/SignUp'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'MemberData',
    path: '/memberData',
    component: () => import('@/pages/MemberData'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Requests',
    path: '/requests',
    component: () => import('@/pages/Request'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('@/pages/Profile'),
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
