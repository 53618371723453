let mutations = {
  setDrawer (state, payload) {
    state.drawer = payload
  },
  setToken(state, data) {
    state.token = data.token;
    state.role = data.role;
  },
  setUserData (state, data) {
    state.username = data.username;
    state.email = data.email;
    state.first_name = data.first_name;
    state.last_name = data.last_name;
    state.line_id = data.line_id;
    state.user_id = data.id;
    state.address = data.address;
    state.phone_number = data.phone_number;
    state.role = data.roles.id;
  },
  setAvatar (state, data) {
    state.avatar = data;
  },
  setTitle (state, data) {
    state.title = data;
  },
}
export default mutations
