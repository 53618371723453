<template>
  <v-footer app border>
    <!-- <v-container> -->
      <v-row justify="center" no-gutters>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column my-1 text-center">
                <div>服務電話：xx-xxxx-xxxx</div>
                <div>服務傳真：xx-xxxx-xxxx</div>
                <div>服務信箱：xxxx@xxxx</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex flex-column justify-end">
          <div class="body-1 font-weight-light pt-2 text-center">
            &copy; 2024, made with
            <v-icon size="18"> mdi-heart </v-icon>
            by xxxx
          </div>
        </v-col>
      </v-row>
    <!-- </v-container> -->
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({}),
};
</script>

<style lang="sass" scoped>
</style>

  