<template>
  <v-app-bar id="app-bar" app color="#4caf50" flat height="75" clipped-left clipped-right>
    <div class="bar-item">
      <template v-if="token">
        <div class="text-h4 title">{{ title }}</div>
      </template>
      <template v-else>
        <div class="text-h4 login-title">{{ title }}</div>
      </template>
      <template v-if="token">
        <div class="btns" @click="setDrawer(!drawer)">
          <img
            :src="require('../assets/logo.svg')"
            width="50"
            height="50"
          />
        </div>
        <div class="btns">
          <v-btn class="ml-2 text-h5" min-width="0" text @click="logout">
            <v-icon>mdi-logout</v-icon>{{ $t('common.logout') }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div></div>
        <div class="header-actions">
          <v-btn class="ml-2 text-h5 test" min-width="0" text v-if="token.length == 0" @click="signUp">
            <v-icon>mdi-account-plus</v-icon>{{ $t('common.signup') }}
          </v-btn>
          <v-btn class="ml-2 text-h5 test" min-width="0" text v-if="token.length == 0" @click="login">
            <v-icon>mdi-account</v-icon>{{ $t('common.login') }}
          </v-btn>
        </div>
      </template>
    </div>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {

  data: () => ({
  }),
  computed: {
    ...mapState(["drawer", "username", "token", "title"]),
  },

  methods: {
    ...mapActions(["sumbitToken"]),
    ...mapMutations(["setDrawer",]),
    logout() {
      this.sumbitToken({
        username: "",
        token: "",
        expiredTime: "",
      });
      this.setDrawer(false)
      this.$router.push({
        name: 'Login',
        params: {}
      })
    },
    login() {
      if(this.$route.name !== 'Login'){
        this.$router.push({
          name: 'Login',
          params: {}
        });
      }
    },
    signUp() {
      if(this.$route.name !== 'SignUp'){
        this.$router.push("/signup")
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.bar-item
  display: flex
  flex: 1
  justify-content: space-between
.title
  position: absolute
  left: 50%
  transform: translate(-50%, 0)
.login-title
  position: absolute
  left: 50%
.btns
  display: flex
  flex-direction: column
  justify-content: center
@media(max-width:960px)
  .header-actions
    display: none
  .login-title
    transform: translate(-50%, -50%)

</style>
