<template>
  <v-app>
    <AppBar />
    <NavigationDrawer />
    <v-main>
      <router-view></router-view>
    </v-main>
    <FooterBar />
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
import FooterBar from './components/FooterBar.vue';
import NavigationDrawer from './components/NavigationDrawer.vue'

export default {
  name: 'App',

  components: {
    AppBar,
    NavigationDrawer,
    FooterBar
},

  data: () => ({
    //
  }),
}
</script>
