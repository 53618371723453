const UserRole = {
    Admin: 1,
    Normal: 2,
    Deliver: 3,
}

const UserRoleItems = [
    { text: 'user_role.Admin', value: UserRole.Admin,},
    { text: 'user_role.Normal', value: UserRole.Normal,},
    { text: 'user_role.Deliver', value: UserRole.Deliver,},
]

const Location = {
    Home: 1,
    Lobby: 2,
    Online: 3,
    Other: 4,
}

const LocationItems = [
    { text: 'request.locations.Home', value: Location.Home},
    { text: 'request.locations.Lobby', value: Location.Lobby},
    { text: 'request.locations.Online', value: Location.Online},
    { text: 'request.locations.Other', value: Location.Other},
]

const IssueProcessStatus = {
    ToBeAssigned: 1,
    InProgress: 2,
    PartiallyResolved: 3,
    FailedToResolved: 4,
    Resolved: 5
}

const IssueProcessStatusItems = [
    { text: 'request.status.ToBeAssigned', value: IssueProcessStatus.ToBeAssigned},
    { text: 'request.status.InProgress', value: IssueProcessStatus.InProgress},
    { text: 'request.status.PartiallyResolved', value: IssueProcessStatus.PartiallyResolved},
    { text: 'request.status.FailedToResolved', value: IssueProcessStatus.FailedToResolved},
    { text: 'request.status.Resolved', value: IssueProcessStatus.Resolved},
]

const IssueProcessPriority = {
    High: 1,
    Normal: 2,
    Low: 3
}

const IssueProcessPriorityItems = [
    { text: 'request.priority.High', value: IssueProcessPriority.High},
    { text: 'request.priority.Normal', value: IssueProcessPriority.Normal},
    { text: 'request.priority.Low', value: IssueProcessPriority.Low},
]

export {
    UserRole,
    UserRoleItems,
    Location,
    LocationItems,
    IssueProcessStatus,
    IssueProcessStatusItems,
    IssueProcessPriority,
    IssueProcessPriorityItems
}