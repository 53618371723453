import { createStore } from "vuex";
import mutations from "./mutations"
import actions from "./actions"
import { UserRole } from '../definition'

export default createStore({
    state: {
        drawer: false,
        token:'',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        line_id: '',
        user_id: '',
        address: '',
        phone_number: '',
        avatar: '',
        role: UserRole.Normal,
        title: '',
    },
    mutations: mutations,
    actions: actions,
});