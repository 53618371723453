<template>
  <v-navigation-drawer
    v-model="drawer"
    expand-on-hover
    rail
    mobile-breakpoint="960"
    width="260"
  >
  <v-list>
    <v-list-item
      :prepend-avatar="avatar"
      :title="username"
      :subtitle="email"
    ></v-list-item>
    <v-divider class="mb-1" />
    <template v-for="(item, i) in computedItems" :key="`list-${i}`">
      <v-list-item
        :prepend-icon="item.icon"
        :title="item.title"
        @click="changePage(item)"
      >
      </v-list-item>
    </template>
  </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { UserRole } from '@/definition'
export default {
  name: 'NavigationDrawer',

  data: (vm) => ({
    items: [
      {
        title: vm.$i18n.t("common.index"),
        icon: 'mdi-home',
        to: 'Index',
      },
      // {
      //   title: vm.$i18n.t("drawer.memberData"),
      //   icon: 'mdi-account',
      //   to: 'MemberData',
      // },
      {
        title: vm.$i18n.t("drawer.request"),
        icon: 'mdi-file-document',
        to: 'Requests',
      },
      {
        title: vm.$i18n.t("drawer.profile"),
        icon: 'mdi-card-account-details',
        to: 'Profile',
      },
    ],
    deliver_items: [
      {
        title: vm.$i18n.t("common.index"),
        icon: 'mdi-home',
        to: 'Index',
      },
      {
        title: vm.$i18n.t("drawer.profile"),
        icon: 'mdi-card-account-details',
        to: 'Profile',
      },
    ],
    admin_items: [
      {
        title: vm.$i18n.t("drawer.index"),
        icon: 'mdi-home',
        to: 'Index',
      },
      {
        title: vm.$i18n.t("drawer.memberData"),
        icon: 'mdi-account',
        to: 'MemberData',
      },
      {
        title: vm.$i18n.t("drawer.request"),
        icon: 'mdi-file-document',
        to: 'Requests',
      },
      {
        title: vm.$i18n.t("drawer.profile"),
        icon: 'mdi-card-account-details',
        to: 'Profile',
      },
    ],
    UserRole: UserRole,
  }),

  computed: {
    ...mapState(["drawer", "username", "email", "token", "avatar", "role"]),
    computedItems () {
      if(this.role === UserRole.Admin){
        return this.admin_items.map(this.mapItem)
      } else if (this.role === UserRole.Deliver){
        return this.deliver_items.map(this.mapItem)
      }
      return this.items.map(this.mapItem)
    },
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        title: this.$t(item.title),
      }
    },
    changePage(item) {
      this.$router.push({
        name: item.to,
        params: {}
      });
    }
  },
}
</script>
