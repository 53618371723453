module.exports = {

  title:{
    Login: "登入",
    Index: "首頁",
    Forget: "忘記密碼",
    ResetPWD: "重設密碼",
    AddProfile: "新增基本資料",
    Profile: "會員資料"
  },
  common:{
    login: "登入",
    signup: "註冊",
    logout: "登出",
    index: "首頁",
    profile: "基本資料",
    actions: "行為",
    creater: "創建人",
    save: "儲存",
    cancel: "取消",
    delete: "刪除",
    back: "回上一頁",
    goBack: "返回",
    recover: "復原",
    confirm: "確認",
    missing: "這個欄位是必填的",
    aboveZero: "必須大於0",
    importFile: "選擇檔案",
    changeAvatar: "變更頭像",
    memo: "備註",
    other: "其它",
    edit: "編輯",
    upload: "上傳",
    desc: "說明",
    noData: "沒有資料",
    waiting: "資料處理中，請稍後...",
    new: "新增",
    next: "下一步"
  },
  drawer: {
    index: "首頁",
    memberData: "會員列表",
    request: "需求單",
    profile: "會員資料"
  },
  login: {
    signup: "還沒有帳號嗎？",
    forget: "忘記密碼",
    failed: "登入失敗 請檢查帳號密碼",
    network: "網路狀況 請稍後再試"
  },
  forget: {
    reset: "重設密碼",
    inputNew: "請輸入您的新密碼",
    new: "新密碼至少為6位英文或數字",
    repeat: "再次輸入新密碼",
    inputProfile1: "請輸入您註冊時使用的墊子信箱或手機號碼",
    inputProfile2: "並於信箱或簡訊查看驗證碼。",
    sendOTP: "發送驗證碼",
    notice: "驗證碼十分鐘內有效，若沒有收到驗證碼，請按『重新發送』按鈕",
    reSend: "重新發送",
    contect: "電子信箱或手機號碼",
    otp: "OTP驗證碼",
    success: "重設成功，請重新登入",
    failed: "重設失敗，請稍後再試"
  },
  signup: {
    next: "下一步",
    chooseUserRole: "選擇帳號類型",
    fillinData: "填入資料",
    createAccount: "創建帳號",
    complete: "完成",
    login: "前往登入",
    success: "註冊成功",
    failed: "註冊失敗",
    validUsername: "這個帳號可以使用",
    invalidUsername: "這個帳號已被使用",
    invalidFormat: "錯誤的格式"
  },
  user_mgr:{
    title : "使用者管理",
    username: "帳號",
    password1: "密碼",
    password2: "確認密碼",
    email: "電子信箱",
    language: "語言",
    first_name: "姓名",
    lineID: "LINE ID",
    gender: {
      title: "性別",
      man: "男",
      woman: "女",
      other: "其他"
    },
    birthday: "生日",
    phone_number: "手機號碼",
    address: "地址",
    community: {
      title: "社區名稱",
      phone_number: "社區電話"
    },
    familyMember: "家中成員",
    contect:{
      name: "第二聯絡人姓名",
      phone_number: "第二聯絡人手機",
      relation: "與會員關係"
    },
    device: {
      title: "使用3C產品",
      smartPhone: "智慧型手機",
      PC: "電腦",
      accessories: "智慧型配件",
      appliances: "家電",
    },
    commonlyUse: "目前常用的功能",
    interest: "興趣",
    demand: "主要需求",
    notice: "注意事項",
    alreadyUsed: "已經使用過哪些服務",
    commonlyProblem: "經常出現的問題",
    checkForm: "資料填寫有誤，請檢查。",
    expiredTime: "有效期限",
    userRole: "使用者類型",
    createUser: "新增使用者",
    updateUser: "修改使用者資料",
    passwordNotMatch: "密碼不相符",
    missingUsername: "帳號不得爲空",
    missingPassword: "密碼不得爲空",
    missingNickname: "暱稱不得爲空",
    missingEmail: "信箱不得爲空",
    missingPhone: "手機號碼不得爲空",
    resetPassword: "重置密碼",
    resetPassword1: "新密碼",
    resetPassword2: "確認新密碼"
  },
  user_role:{
    Admin: "管理者",
    Normal: "一般使用者",
    Deliver: "科技外送員"
  },
  main_page: {
    lineID: "官方LINE ID"
  },
  member: {
    customerNumber: "客戶編號",
    email: "E-mail",
    firstName: "姓名",
    lineId: "LINE-ID",
  },
  request: {
    checkout: "查看細節",
    requestNumber: "訂單編號",
    createTime: "建立時間",
    process_status_id: "處理狀態",
    process_priority_id: "優先等級",
    services: "服務項目",
    detail: "需求詳細說明",
    location: "服務地點",
    time: "服務日期 / 時間",
    contect: "聯絡電話",
    confirm: "資料確認",
    maxMultiSelect: "最多可複選{max}項",
    tel: "室話",
    phone: "手機",
    locations: {
      Home: "客戶住家",
      Lobby: "客戶大廳",
      Online: "線上服務",
      Other: "客戶指定位置",
    },
    username: "客戶名稱",
    contectAddress: "聯絡地址",
    serviceTime: "服務時間",
    requestInfo: "詳細說明",
    save: "確認送出",
    success: "建立成功",
    failed: "建立失敗",
    status: {
      ToBeAssigned: "待處理",
      InProgress: "處理中",
      PartiallyResolved: "部分解決",
      FailedToResolved: "無法解決",
      Resolved: "已解決",
    },
    priority: {
      High: "高",
      Normal: "中",
      Low: "低",
    }
  },
  vuetify:{
    dataFooter: {
      itemsPerPageText: "單頁筆數",
      pageText: "頁"
    },
    confirmEdit:{
      ok: "確認",
      cancel: "取消"
    }
  }
}
